require('./head/modernizr.js')
require('./head/polyfills.js')
import Glide from '@glidejs/glide'
;(function (global) {
    var simplefocus = {
        // Initialize everything
        init() {
            this.reveal()
            this.highlighter()
            this.hamburger()
            this.mobileDropdowns()
            this.searchReveal()
            this.testimonialSlider()
            // this.newsletterConfirmation()
        },

        reveal() {
            const targets = document.querySelectorAll('.animated')

            function handleIntersection(entries) {
                entries.map(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('reveal')
                    } else {
                        // entry.target.classList.remove('reveal')
                    }
                })
            }

            const options = {
                threshold: 0.9,
            }

            const observer = new IntersectionObserver(
                handleIntersection,
                options
            )
            targets.forEach(function (target) {
                observer.observe(target)
            })
        },

        highlighter() {
            const highlightedItems = document.querySelectorAll('.highlighted')

            function handleIntersection(entries) {
                entries.map(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('highlighted-active')
                    } else {
                        // entry.target.classList.remove('reveal')
                    }
                })
            }

            const options = {
                threshold: 0.9,
            }

            const observer = new IntersectionObserver(
                handleIntersection,
                options
            )
            highlightedItems.forEach(function (higlightedItem) {
                observer.observe(higlightedItem)
            })
        },

        hamburger() {
            var hamburger = document.querySelector('.hamburger')
            var menu = document.getElementById('main-nav')

            hamburger.addEventListener('click', function () {
                menu.classList.toggle('hidden')
                hamburger.classList.toggle('is-active')
            })
        },

        mobileDropdowns() {
            var mobileDropdownToggle = document.querySelectorAll(
                '.mobile-dropdown-toggle'
            )

            mobileDropdownToggle.forEach(function (toggle) {
                toggle.addEventListener('click', function () {
                    var submenu = toggle.parentNode.querySelector('ul')
                    toggle.classList.toggle('active') // Changes appearance of toggle icon
                    submenu.classList.toggle('hidden') // Shows the submenu
                })
            })
        },

        searchReveal() {
            var searchTrigger = document.getElementById('searchTrigger')
            var searchBar = document.getElementById('searchBar')
            var searchInput = document.getElementById('searchInput')
            var searchClose = document.getElementById('searchClose')

            searchTrigger.addEventListener('click', function () {
                searchBar.classList.remove('-translate-y-full', 'opacity-0')
                searchInput.focus()
                searchInput.value = ''
            })

            searchClose.addEventListener('click', function () {
                searchBar.classList.add('-translate-y-full', 'opacity-0')
                searchTrigger.focus()
            })
        },

        testimonialSlider() {
            window.addEventListener('load', function () {
                var sliderExists = document.querySelector('.glide')
                if (sliderExists) {
                    new Glide('.glide', {
                        type: 'carousel',
                        startAt: 0,
                        perView: 1,
                    }).mount()
                }
            })
        },

        // newsletterConfirmation() {
        //     let url = window.location.href
        //     var notificationBar = document.getElementById('notificationBar')
        //     var notificationClose = document.getElementById('notificationClose')

        //     if (url.includes('?submitted=true')) {
        //         notificationBar.classList.remove('hidden')
        //         notificationClose.focus()
        //     } else {
        //         // Do nothing
        //     }

        //     notificationClose.addEventListener('click', function () {
        //         notificationBar.classList.add('hidden')
        //     })
        // },
    }

    // Add window object
    global.simplefocus = simplefocus

    // Run it
    simplefocus.init()
})(window)
